// extracted by mini-css-extract-plugin
export var anchor = "Manifesto-module--anchor--adyln";
export var background = "Manifesto-module--background--+EZBS";
export var citations = "Manifesto-module--citations--II-P3";
export var container = "Manifesto-module--container--AH8Td";
export var instagram = "Manifesto-module--instagram--38tAp";
export var items = "Manifesto-module--items--vo4nH";
export var logo = "Manifesto-module--logo--0nORk";
export var manifesto = "Manifesto-module--manifesto--05sL7";
export var signatures = "Manifesto-module--signatures--KxbvY";
export var signaturesForm = "Manifesto-module--signatures-form--bHfk6";
export var signaturesTitle = "Manifesto-module--signatures-title--sPLWX";
export var tabs = "Manifesto-module--tabs--xVsCd";
export var tabsContainer = "Manifesto-module--tabs-container--VFVdF";
export var wrap = "Manifesto-module--wrap--mVF7w";