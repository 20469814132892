import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage} from "gatsby-plugin-image";

import * as styles from './Galeria.module.scss';

const Galeria = ({data}) => {

    return (
        <section id={styles.portfolio}>
            {data.map((item, index) => {
                    return (
                        <div className={styles.project}>
                            <GatsbyImage image={item.photo}
                                         className={styles.project__image}
                                         alt={"Vídeo " + (index + 1)}/>
                            <div className={styles.grid__overlay}>
                                <a href={item.link} target="_blank" rel="noreferrer noopener">
                                    <button>Play</button>
                                </a>
                            </div>
                        </div>
                    )
                }
            )}
        </section>
    );

}

Galeria.propTypes = {
    data: PropTypes.array,
};

export default Galeria;
