import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Manifesto from "../components/Manifesto";
import Apoiadores from "../components/Apoiadores";
import SocialNetworks from "../components/SocialNetworks";
import {GatsbyImage} from "gatsby-plugin-image";
import Galeria from '../components/Galeria';
import MenuMain from "../components/MenuMain";

export default function render({data}) {
    CampaignForm.channelId = data.site.meta.channel;

    return (
        <Layout>
            <SEO title={data.site.meta.title} ogImage={data.ogImage}/>

            <MenuMain data={data}/>

            <Hero
                left="Memória e justiça racial na cidade de São Paulo."
                logo={data.hero.childImageSharp.gatsbyImageData}
                right="Assine.
                Compartilhe.
                Construa."
                channel={data.site.meta.channel}

            />

            <Manifesto share={data.site.meta.description}
                       background={data.manifesto.childImageSharp.gatsbyImageData}/>

                <Galeria data={[
                    {
                      link: "https://www.youtube.com/watch?v=jL9zcGXf4VQ&t=2625s",
                      photo: data.midia1.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=WLEz56zxASE",
                      photo: data.midia2.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=3BFLvo4F73A",
                      photo: data.midia3.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=Mr301dgVHS8&t=5s",
                      photo: data.midia4.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=y_tKDCBimTQ",
                      photo: data.midia5.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=PaEHqdBhHwY",
                      photo: data.midia6.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=PAvHFJ_SYaU&t=6s",
                      photo: data.midia7.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://www.youtube.com/watch?v=c-BrCleci2k",
                      photo: data.midia8.childImageSharp.gatsbyImageData
                    },
                    {
                      link: "https://open.spotify.com/episode/3ERKSrq0qgvK1Wl4xEKBzK?si=df46d3a0294d4cf8",
                      photo: data.midia9.childImageSharp.gatsbyImageData
                    },


                ]}


                />

            <Apoiadores data={[
                {
                    name: "Luana Alves",
                    organizacao: "Líder da bancada do PSOL-SP",
                    photo: data.luana.childImageSharp.gatsbyImageData
                }, {
                    name: "Rede Emancipa",
                    organizacao: "Movimento Social de Educação Popular",
                    photo: data.emancipa.childImageSharp.gatsbyImageData
                },
                {
                    name: "Silvio de Almeida",
                    organizacao: "Instituto Luiz Gama",
                    photo: data.silvioDeAlmeida.childImageSharp.gatsbyImageData
                },
                {
                    name: "Chirley Pankará",
                    organizacao: "Mandata Ativista",
                    photo: data.chirley.childImageSharp.gatsbyImageData
                },
                {
                    name: "Abilio Ferreira",
                    organizacao: "Instituto Tebas",
                    photo: data.abilio.childImageSharp.gatsbyImageData
                },
                {
                    name: "Mestra Janja",
                    organizacao: "Grupo Nzinga",
                    photo: data.janja.childImageSharp.gatsbyImageData
                },
                {
                    name: "Erika Hilton",
                    organizacao: "Vereadora pelo PSOL SP",
                    photo: data.erika.childImageSharp.gatsbyImageData
                },
                {
                    name: "Milton Barbosa",
                    organizacao: "Movimento Negro Unificado",
                    photo: data.milton.childImageSharp.gatsbyImageData
                },
                {
                    name: "Clarice Pankararu",
                    organizacao: "S.O.S. Pankararu do Real Parque",
                    photo: data.claricePankararu.childImageSharp.gatsbyImageData
                },
                {
                    name: "Jairo Pereira",
                    organizacao: "Aláfia",
                    photo: data.jairo.childImageSharp.gatsbyImageData
                },
                {
                    name: "Baby Amorim",
                    organizacao: "Ilú Obá De Min",
                    photo: data.baby.childImageSharp.gatsbyImageData
                },
                {
                    name: "Elaine Mineiro",
                    organizacao: "Mandato Quilombo Periférico",
                    photo: data.elaine.childImageSharp.gatsbyImageData
                },
                {
                    name: "Geledés",
                    organizacao: "Instituto da Mulher Negra",
                    photo: data.geledes.childImageSharp.gatsbyImageData
                },
                {
                    name: "MNU",
                    organizacao: "Movimento Negro Unificado",
                    photo: data.mnu.childImageSharp.gatsbyImageData
                },
                {
                    name: "IPEAFRO",
                    organizacao: "Instituto de Pesquisas e Estudos Afro-Brasileiros",
                    photo: data.ipeafro.childImageSharp.gatsbyImageData
                },
                {
                    name: "ABPN",
                    organizacao: "Associação Brasileira de Pesquisadores/as Negros/as",
                    photo: data.bpn.childImageSharp.gatsbyImageData
                },
                {
                    name: "MMNSP",
                    organizacao: "Marcha das Mulheres Negras de São Paulo",
                    photo: data.marcha.childImageSharp.gatsbyImageData
                },
                {
                    name: "SaraUpovo",
                    organizacao: "Cultura, educação e historiografia",
                    photo: data.sarauPovo.childImageSharp.gatsbyImageData
                },
                {name: "Mestre Tião Carvalho", organizacao: "Grupo Cupuaçu", photo: data.tiao.childImageSharp.gatsbyImageData},
                {name: "Mestre Dinho Nascimento", organizacao: "", photo: data.dinho.childImageSharp.gatsbyImageData},
                {name: "Instituto Vladimir Herzog", organizacao: "", photo: data.herzog.childImageSharp.gatsbyImageData},
                {name: "Coletivo Som Na Praça", organizacao: "", photo: data.snp.childImageSharp.gatsbyImageData},
                {name: "Feminine H-Fi", organizacao: "", photo: data.feminine.childImageSharp.gatsbyImageData},
                {name: "Portal RAS", organizacao: "Associação Nacional do Reggae", photo: data.ras.childImageSharp.gatsbyImageData},
                {name: "Coletivo Cartografia Negra", organizacao: "", photo: data.cartografia.childImageSharp.gatsbyImageData},
                {name: "ANATORG", organizacao: "Associação Nacional das Torcidas Organizadas", photo: data.anatorg.childImageSharp.gatsbyImageData},
                {name: "Programa Já regou suas plantas?", organizacao: "98,9 FM", photo: data.regou.childImageSharp.gatsbyImageData}
            ]}/>



            <footer className="footer">
                <div className="wrap">
                    <div className="description">
                        <div className="info">
                            <h1>Uma iniciativa do mandato da vereadora Luana Alves</h1>
                        </div>
                        <div className="social-networks">
                            <SocialNetworks
                                twitter="luanapsol"
                                facebook="luanapsol"
                                instagram="luanapsol"
                                youtube="channel/UClZyv3izFgsogtxHI1Z9Cxw"
                                email="contato@luanapsol.com.br"
                            />
                        </div>
                    </div>
                    <div className="logos">
                        <GatsbyImage className="logo" alt="Punho - SP é Solo Preto!"
                             objectFit='contain'
                             style={{position: false}}
                             image={data.punho.childImageSharp.gatsbyImageData}/>
                    </div>
                </div>
            </footer>
        </Layout>
    );
}

render.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "SPSOLO-LOGO.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    
    manifesto: file(relativePath: { eq: "pankararu.png" }) {
      childImageSharp {
      gatsbyImageData(width: 1200)
      }
    }
    
    logo: file(relativePath: { eq: "pretoindigena.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        gatsbyImageData(width: 30)
      }
    }
    
    ogImage: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
    
    iniciativas: file(relativePath: { eq: "iniciativas.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    formacao: file(relativePath: { eq: "curso.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    formacaoLogos: file(relativePath: { eq: "formacao-logos.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    punho: file(relativePath: { eq: "punho-footer.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    
    luana: file(relativePath: { eq: "apoiadores/luana alves.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    
    bpn: file(relativePath: { eq: "apoiadores/BPN.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    
    elaine: file(relativePath: { eq: "apoiadores/Elaine.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    geledes: file(relativePath: { eq: "apoiadores/geledés.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    ipeafro: file(relativePath: { eq: "apoiadores/IPEAFRO.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    marcha: file(relativePath: { eq: "apoiadores/marcha.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    mnu: file(relativePath: { eq: "apoiadores/MNU.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    sarauPovo: file(relativePath: { eq: "apoiadores/Saraupovo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    silvioDeAlmeida: file(relativePath: { eq: "apoiadores/silvio almeida.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    abilio: file(relativePath: { eq: "apoiadores/abilio.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    baby: file(relativePath: { eq: "apoiadores/baby.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    chirley: file(relativePath: { eq: "apoiadores/chirley.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    claricePankararu: file(relativePath: { eq: "apoiadores/clarice pankararu.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    erika: file(relativePath: { eq: "apoiadores/erika.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    jairo: file(relativePath: { eq: "apoiadores/jairo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    janja: file(relativePath: { eq: "apoiadores/janja.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    milton: file(relativePath: { eq: "apoiadores/milton.png" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    emancipa: file(relativePath: { eq: "apoiadores/rede emancipa.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    tiao: file(relativePath: { eq: "apoiadores/mestre tiao carvalho.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    dinho: file(relativePath: { eq: "apoiadores/mestre dinho.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    herzog: file(relativePath: { eq: "apoiadores/herzog.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    snp: file(relativePath: { eq: "apoiadores/coletivo SNP.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    feminine: file(relativePath: { eq: "apoiadores/feminine hifi.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    ras: file(relativePath: { eq: "apoiadores/PORTAL RAS.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    cartografia: file(relativePath: { eq: "apoiadores/cartografia negra.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    anatorg: file(relativePath: { eq: "apoiadores/anatorg.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }
    
    regou: file(relativePath: { eq: "apoiadores/ja regou.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 220)
      }
    }

    midia1: file(relativePath: { eq: "midias/Grade-Feed-Videos_01.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia2: file(relativePath: { eq: "midias/Grade-Feed-Videos_02.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia3: file(relativePath: { eq: "midias/Grade-Feed-Videos_03.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia4: file(relativePath: { eq: "midias/Grade-Feed-Videos_04.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia5: file(relativePath: { eq: "midias/Grade-Feed-Videos_05.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia6: file(relativePath: { eq: "midias/Grade-Feed-Videos_06.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia7: file(relativePath: { eq: "midias/Grade-Feed-Videos_07.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia8: file(relativePath: { eq: "midias/Grade-Feed-Videos_08.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }

    midia9: file(relativePath: { eq: "midias/Grade-Feed-Videos_09.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }  
  }
`;
